import StackTracey from 'stacktracey';
import log from 'loglevel';

class Logger {
  async exception(message, err) {
    try {
      let stack = new StackTracey(err);
      stack = await stack.cleanAsync();
      stack = stack.asTable({
        callee: 30,
        file: 60,
        sourceLine: 250,
      });
      this.post(message, stack, 'exception');
      this.raiseEvent(message, stack);
    } catch (error) {
      log.error(error);
    }
  }

  async error(message, details) {
    console.log('errrr in ERROR');
    if (!details) {
      details = new StackTracey();
      try {
        details = await details.cleanAsync();
        details = details.asTable({
          callee: 30,
          file: 60,
          sourceLine: 250,
        });
      } catch (err) {
        details = 'ERROR in Logger.error StackTracey: ' + err.toString();
      }
    }
    this.post(message, details, 'error');
  }

  async post(message, details, type) {
    if (window.location.hostname !== 'localhost' && window.location.hostname.substr(0, 7) !== '192.168') {
      try {
        const data = {
          message: message.toString(),
          stack: details,
          timestamp: Date.now() / 1000,
          useragent: window.navigator.userAgent,
          type: type,
          hostname: window.location.hostname,
          url: window.location.href,
        };
        fetch('https://logerror.marcus.ax/index.php?action=log&type=' + type, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data);
          })
          .catch((error) => {
            log.debug('Error:', error);
          });
      } catch (error) {
        // ignore error in error logging...
        log.debug(error);
      }
    }
  }

  addEventListener(callback) {
    if (!this.callback) this.callback = [];
    this.callback.push(callback);
  }

  raiseEvent(message, stack) {
    if (this.callback) this.callback.forEach((f) => f(message, stack));
  }
}

const logger = new Logger();

export default logger;
