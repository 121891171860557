import firebase from './firebase';

export default class ExerciseLevels {

    constructor(levels, uid) {
        this.uid = uid;
        this.levels = levels ? levels : {};
    }

    static fromFirebase(data, uid) {
        const levels = new ExerciseLevels(data, uid);
        return levels;
    }

    static fromAnonymous() {
        const data = {
            rhythmPlay: {default: 0}
        }
        const levels = new ExerciseLevels(data, null)
        return levels;
    }

    updateFromFirebase(data) {
        //log.debug('syllabus update from firebase', data);
        this.levels = data;
    }

    getLevelValue(key, level) {
        if (this.levels && key in this.levels && level in this.levels[key]) {
            return this.levels[key][level];
        } else {
            return 0;
        }
    }

    getLevelDefault(key) {
        if (this.levels && key in this.levels && 'default' in this.levels[key]) {
            return this.levels[key].default;
        } else {
            return 0;
        }
    }

    updateLevelValue(key, level, value) {
        if (!(key in this.levels)) this.levels[key] = {}
        this.levels[key][level] = value
        firebase.db.collection('users').doc(this.uid).update({levels: this.levels});
    }
    
    updateLevelDefault(key, value) {
        if (!(key in this.levels)) this.levels[key] = {}
        this.levels[key].default = value
        firebase.db.collection('users').doc(this.uid).update({levels: this.levels});
    }
}