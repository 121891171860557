import firebaseapp from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import User from './User';
import router from './router';

import log from 'loglevel';

//let db = firebase.firestore();

/*export default db;

export {firebase};*/


class Firebase {
  constructor(forcePublic = false) {
    this.isDev = window.location.hostname === 'localhost' || window.location.hostname.substr(0,7) === '192.168';
    const devSite = 'tunelearner'; // ami, public, tunelearner
    let firebaseConfig;
    this.forcePublic = forcePublic;
    if (!forcePublic && ((this.isDev && devSite === 'ami') || window.location.hostname === 'teori.marcus.ax')) {
    //if (window.location.hostname === 'teori.marcus.ax') {
      firebaseConfig = {
        apiKey: "AIzaSyBYmiyIV208yOnaRd_KsgFYZz3UUQvcegs",
        authDomain: "musig-83b1d.firebaseapp.com",
        databaseURL: "https://musig-83b1d.firebaseio.com",
        projectId: "musig-83b1d",
        storageBucket: "musig-83b1d.appspot.com",
        messagingSenderId: "836535272379",
        appId: "1:836535272379:web:482f4199dbd4c8017e7a9e"
      };
      this.isAmi = true;
    } else if (forcePublic || ((this.isDev && devSite === 'public') || (window.location.hostname === 'musik.marcus.ax' || window.location.hostname === 'musik.ax'))) {
    //} else if (isDev || window.location.hostname === 'musik.marcus.ax') {
      // Your web app's Firebase configuration
      firebaseConfig = {
        apiKey: "AIzaSyALBHA2MXRmUeiL4EH4KY6mvNUw4EY8KzY",
        authDomain: "musig-public.firebaseapp.com",
        databaseURL: "https://musig-public.firebaseio.com",
        projectId: "musig-public",
        storageBucket: "musig-public.appspot.com",
        messagingSenderId: "412629404650",
        appId: "1:412629404650:web:81500be287a97da5fd4093"
      };
      this.isPublic = true;
      console.log('firebase public');
    } else if ((this.isDev && devSite === 'tunelearner') || (window.location.hostname === 'tunelearner.marcus.ax')) {
      firebaseConfig = {
        apiKey: "AIzaSyALBHA2MXRmUeiL4EH4KY6mvNUw4EY8KzY",
        authDomain: "musig-public.firebaseapp.com",
        databaseURL: "https://musig-public.firebaseio.com",
        projectId: "musig-public",
        storageBucket: "musig-public.appspot.com",
        messagingSenderId: "412629404650",
        appId: "1:412629404650:web:81500be287a97da5fd4093"
      };
      this.isTuneLearner = true;
      console.log('firebase tunelearner');
    } else {
      throw new Error('Unknown site location error')
    }

    this.firebase = firebaseapp;
    this.firebaseApp = this.firebase.initializeApp(firebaseConfig, !this.forcePublic ? '[DEFAULT]' : 'public');
    this.storage = this.firebase.storage();
    this.db = this.firebase.firestore(this.firebaseApp);
    this.ui = undefined;
  }

  onSignIn(callback) {
    this.signInCallback = callback;
  }

  authAnonymous() {
    // this is not an firebase anonymous user, just an 'internal' anonymous user
    // which is NOT saved across page reloads
    document.querySelector('#auth-wrapper').style.display = 'none';
    User.setCurrentUser(User.fromAnonymous());
    if (this.signInCallback) this.signInCallback();
  }

  auth() {
    if (this.forcePublic) return;

    this.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //log.debug('USER', user)
        const userObj = user;
        user.getIdToken().then(accessToken => {
          const user = new User.fromFirebase(userObj)
          .then(user => {
            User.setCurrentUser(user);
            if (this.signInCallback) this.signInCallback();
          });
        });
      } else {
        // User is signed out.
        //this.currentUser = undefined;
        User.setCurrentUser(undefined);
        this.showAuthUI();
      }
    }, (error) => {
      User.setCurrentUser(undefined);
      //this.currentUser = undefined;
      log.debug(error);
    });
  }

  async signOut() {
    if (this.forcePublic) return;
    await this.firebase.auth().signOut()
    router.goto('/');
  }

  async showAuthUI() {
    if (this.forcePublic) return;
    window.history.pushState({}, '', '/');
    if (!this.ui) {
      const ui = await this.getUI('./firebaseui');
      this.ui = new ui(this.firebase);
    }
    this.ui.showAuthUI();
  }

  async getUI() {
    const { default: _ } = await import(/* webpackChunkName: "app" */ './firebaseui');
    return _;
  }
}

const firebase = new Firebase();
export default firebase;
export {firebase}
const db = firebase.db;
export {db};

let pubDb;
const getPublicDatabase = () => {
  if (!pubDb) pubDb = new Firebase(true);
  return pubDb;
}
export {getPublicDatabase}