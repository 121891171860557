import firebase from './firebase';

import log from 'loglevel';

/**
 * Handels a user review exercises
 */
export default class Review {

    /**
     * @typedef {Object} ReviewData
     * @property {ReviewDataItem} reviewDataItem - key is name of subject. Multiple items may exist
     * For list of subject, see end of file
     */

    /**
     * @typedef {Object} ReviewDataItem
     * @property {String} name - Name of exercise
     * @property {String} exerciseId - id of exercise
     * @property {String} status - active/completed
     * @property {Date} nextReview - date of next review
     * @property {Number} lastResult - percentage correct on last review
     */

    /*
melody.notes.diatonic.piano = {
    [
        {
            name: 'Spela på piano',
            exerciseId: 'sdfs',
            nextReview: 'datum...',
            lastResult: 73
        }
    ]
}
    */
    
    /**
     * 
     * @param {ReviewData} data 
     * @param {String} uid - firebase uid
     * @returns Review
     */
    static fromFirebase(data, uid) {
        const review = new Review();
        let reviewData = data.review
        if (!reviewData) reviewData = {}
        review.data = reviewData;
        let sessionData = data.reviewSessions
        if (!sessionData) sessionData = []
        review.sessionData = sessionData;
        review.uid = uid;
        return review;
    }

    hasPending() {
        let hasPending = 0;
        Object.keys(this.data).forEach(key => {
            if (this.data[key].status === 'active' && (Date.now() / 1000) - this.data[key].nextReview.seconds > 0) hasPending++;
        })
        return hasPending;
    }

    getNext() {
        let data;
        Object.keys(this.data).sort().forEach(key => {
            if (!data && this.data[key].status === 'active' && (Date.now() / 1000) - this.data[key].nextReview.seconds > 0) {
                data = this.data[key];
                data.id = key;
            }
        })
        return data;
    }

    activateReview(id, activate = true) {
        if (!(id in this.data)) {
            this.data[id] = Review.getReviewDataItem(id)
        }
        this.data[id].nextReview = new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0)
        this.data[id].status = activate ? 'active' : 'deactivated';

        firebase.db.collection('users').doc(this.uid).set({
            review: this.data
          }, {merge: true})
    }
        
    /**
     * 
     * @param {String} id - id of review exercise
     * @param {Number} result - percentage correct
     * @returns 
     */
    completeReview(id, result) {
        if (!(id in this.data)) return
        
        this.data[id].status = 'completed';
        this.data[id].lastResult = result;

        this.sessionData[this.sessionData.length - 1][Math.floor(Date.now()/1000)] = {
            id: id,
            result: result
        }
        
        firebase.db.collection('users').doc(this.uid).set({
            review: this.data,
            reviewSessions: this.sessionData
        }, {merge: true})
    }

    static async getLatestStatistics(uid) {
        const userRef = firebase.db.doc('users/'+uid);

        const docs = await firebase.db.collection('userStatistics').where('userRef', '==', userRef).where('reviewId', '!=', '').orderBy('reviewId', 'desc').limit(20).get();
        return docs;
    }

    newReviewSession() {
        const id =  Math.floor(Date.now()/1000);
        this.sessionData.push({id: id})
        firebase.db.collection('users').doc(this.uid).update({
            reviewSessions: this.sessionData
        })
        return id;
    }

    static async getUserReviews(uid) {
        const userData = await firebase.db.collection('users').doc(uid).get();
        const review = Review.fromFirebase(userData.data(), uid);
        return review;
    }

    /**
     * Gets template data for all reviews
     */
    static async getReviewData() {
        const docs = await firebase.db.collection('reviewData').get();
        Review.reviewData = {};
        docs.forEach(doc => {
            Review.reviewData[doc.id] = doc.data();
        })
    }

    /**
     * Returns shallow copy of Review.reviewData[key]
     * @param {String} key 
     * @returns {Object}
     */
    static getReviewDataItem(key) {
        const item = {};
        // make shallow copy
        Object.keys(Review.reviewData[key]).forEach(dataKey => item[dataKey] = Review.reviewData[key][dataKey]);
        return item;
    }

    /**
     * Merges user data with review template data
     * @returns {Object}
     */
    async getUserStatus() {
        if (!Review.reviewData) await Review.getReviewData();
        const userData = {};
        Object.keys(Review.reviewData).forEach(key => {
            if (key in this.data) {
                userData[key] = this.data[key];
            } else {
                userData[key] = Review.getReviewDataItem(key);
            }
        })
        return userData;
    }

    /*
# = "title subject", children not shown in overview. Maybe ability are these?

rhythm
    quarter
        #plain
            math
            4/4
                read // read with voice
                    figure 1-4
                    figure 5-6
                    figure 7-8
                    ALSO: different levels of difficulty, maybe 1-3?
                play
                write
        #ties
            read
            play
            write
        #rests
            read
            play
            write
    eights
        plain
            math
            syncopation
            2/4
                read
                play
                    lvl1 - old, unused
                    lvl2 - 1-4
                        2b - 2 bars
                        4b
                    lvl3 - 5-6
                    lvl4 - 7-8
                    lvl5 - all patterns
                write
                explain (beskriv muntligt notbilden)
            3/4
            4/4
                read
                play
                write
        ties
        rests
        swing
        notation
            beats
                4/4 
                    write
                        lvl1
    sixteens
    1/32
    polyrythmics
        duplet
        triplet
        quart
        quint
        sext
        sept
    timesignatures
        3/8
        6/8
        2/2
        9/8
        12/8
        3/2
        5/4
        7/5
    "växlande taktart"
    "theory"
        pick-up
        articulation
        sections
        form
        repeats
        tempo
        dynamics
    [unsorted]
        beat/bar
        timesignature
            identify
                listen
                sheetmusic
melody
    note
        system
        diatonic (no octave)
            piano RENAME names?
                play // from text
                identify
            g-clef
                play
                read
                    answer: REMOVE?
                        - buttons
                        > piano
                write
                    from:
                        - text
                        - piano
            f-clef
                play
                    piano
                read
                    answer:
                        - buttons
                        > piano
                write
                    from:
                        - text
                        - piano
        octaves
            common // 4
                piano
                    play // from text
                    identify
                g-clef
                    play
                        piano
                    read
                        answer:
                            - piano
                            - buttons
                    write
                f-clef
                    play
                        piano
                c-clef
                    tenor
                    alto
                    (...)
            all
                piano
                g-clef
                f-clef
                c-clef
                    tenor
                    alto
                    (...)
            "8va etc"
        accidentials
            notesteps
            piano
                common // black keys
                    play
                    identifiy
                strange // notes with acc that still are white keys
                double
                all
            g-clef
            f-clef
            c-clef
                (...)
    key
        major
            c
                tunes
                    play
                    sing
                    write
            tunes
                play
                sing
                write
            key
                write
                identify
            (...)
            rhymes "ramsor"
            circle of fifths
        minor
            introduction
                about
                sing
                write
            tunes
                play
                sing
                write
            key
                fromMajor
                identify
        relativeKey ("parallelltonart")
            fromMajor
            steps
        sign
            all
        accidentials "tillfälliga förtecken"
        guideTones
    scale
        major
        minor
            "ren"
            harmonic
                write
            melodic
        chromatic
        pentatonic
            major
            minor
        blues
    modi
    transpose
        diatonic
        accidentials
        transposing instruments
    [unsorted]
        "rättskrivning" notskaft
interval
    diatonic
        read
            score
                subSections:
                    - 1-5
                    - 5-8
                    - 1-8
            piano
        write
        listen
    minor
        read
            score
                subSections:
                    - common // 2,3,6,7
    augDim
        read
            score
                subSections:
                    - common // 1-8 only duplicates a4p5 and a5m6
        about
harmony
    chord
        powerchord
        majorMinor
            play
                piano
                    from:
                        - description (steps)
                        - chord symbol
                        - sheetmusic
            listen
            write
        augDim
        sus
        seventh
        extended
    progression
        major
            15
            145
                analysis
            1245
            12456
            123456
    voiceLeading
form
    repeats
        repeatMarks
symblols NEED BETTER NAME
    tempo
        fermata
    articulation
     */
}